
import Sidebar from "../Sidebar/index";
import Navbar from "../Navbar/index";
import React from 'react';

export const LayoutOne = ({ children }) =>
    <div>
        {children}
    </div>;

export const LayoutTwo = ({ children }) =>
    <div>
        <div>
            <div className="top-nav-bar">
                <Navbar />
            </div>
            <div className="side-menu-bar">
                <Sidebar />
            </div>
        </div>
        {children}
    </div>;
