import { treeActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
    createTree: '',
    treesList: [],
    editedTree: ''
};

const treeReducer = (state = initialState, action) => {
    const newState = cloneDeep(state);
    switch (action.type) {
        case treeActionTypes.CREATE_TREE: {
            return { ...newState, loading: true };
        }
        case treeActionTypes.CREATE_TREE_SUCCESS: {
            newState.loading = false;
            newState.createTree = action.result;
            return newState;
        }
        case treeActionTypes.GET_TREES: {
            return { ...newState, loading: true };
        }
        case treeActionTypes.GET_TREES_SUCCESS: {
            newState.loading = false;
            newState.treesList = action.result;
            return newState;
        }
        case treeActionTypes.EDIT_TREE: {
            return { ...newState, loading: true };
        }
        case treeActionTypes.EDIT_TREE_SUCCESS: {
            newState.loading = false;
            newState.editedTree = action.result;
            return newState;
        }
        default:
            return newState;
    }
};

export default treeReducer;
