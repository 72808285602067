import { loginActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
  adminLogin: [],
};

const adminLoginReducer = (state = initialState, action) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case loginActionTypes.ADMIN_LOGIN_REQUEST: {
      return { ...newState, loading: true };
    }
    case loginActionTypes.ADMIN_LOGIN_SUCCESS: {
      newState.loading = false;
      newState.adminLogin = action.result;
      return newState;
    }
    case loginActionTypes.ADMIN_LOGIN_ERROR: {
      return { ...newState, loading: true, errors: action.payload };
    }
    default:
      return newState;
  }
};

export default adminLoginReducer;
