import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';

import { codiclustSearchActionTypes } from '../constants';

function* handleAdminLogin(request) {
  const { payload } = request;
  try {
    const res = yield call(api.login, payload);
    yield put({ type: 'FETCH_SEARCH_SUCCESS', res });
  } catch (err) {
    yield put({ type: 'FETCH_SEARCH_ERROR', ...err });
  }
}

export function* searchSaga() {
  yield all([
    yield takeLatest(codiclustSearchActionTypes.FETCH_SEARCH_REQUEST, handleAdminLogin),
  ]);
}