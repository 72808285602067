import { reportedPostActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
    Posts: [],
    postStatus: ''
};

const reportedPostsReducer = (state = initialState, action) => {
    const newState = cloneDeep(state);
    switch (action.type) {
        case reportedPostActionTypes.GET_ALL_REPORTED_POSTS: {
            return { ...newState, loading: true };
        }
        case reportedPostActionTypes.GET_ALL_REPORTED_POSTS_SUCCESS: {
            newState.loading = false;
            newState.Posts = action.result;
            return newState;
        }
        case reportedPostActionTypes.GET_ALL_REPORTED_POSTS_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case reportedPostActionTypes.POST_STATUS: {
            return { ...newState, loading: true };
        }
        case reportedPostActionTypes.POST_STATUS_SUCCESS: {
            newState.loading = false;
            newState.postStatus = action.result;
            return newState;
        }
        case reportedPostActionTypes.POST_STATUS_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }

        default:
            return newState;
    }
};

export default reportedPostsReducer;
