import { userActionTypes } from '../constants';
export const getUsers = () => ({
    type: userActionTypes.GET_ALL_USERS,
    payload: ''
});
export const getUsersSuccess = data => ({
    type: userActionTypes.GET_ALL_USERS_SUCCESS,
    payload: data
});
export const getUsersError = message => ({
    type: userActionTypes.GET_ALL_USERS_ERROR,
    payload: message
});
export const DeleteUsers = data => ({
    type: userActionTypes.DELETE_USERS,
    payload: data
});
export const DeleteUsersSuccess = data => ({
    type: userActionTypes.DELETE_USERS_SUCCESS,
    payload: data
});
export const DeleteUsersError = message => ({
    type: userActionTypes.DELETE_USERS_ERROR,
    payload: message
});
export const updateStatus = data => ({
    type: userActionTypes.UPDATE_STATUS,
    payload: data
});
export const updateStatusSuccess = data => ({
    type: userActionTypes.UPDATE_STATUS_SUCCESS,
    payload: data
});
export const updateStatusError = message => ({
    type: userActionTypes.UPDATE_STATUS_ERROR,
    payload: message
});
