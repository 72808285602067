import { badgesActionTypes } from '../constants';
export const createBadge = (data, finalData) => ({
    type: badgesActionTypes.CREATE_BADGE,
    payload: data,
    finalData: finalData
});
export const createBadgeSuccess = data => ({
    type: badgesActionTypes.CREATE_BADGE_SUCCESS,
    payload: data
});
export const createBadgeError = message => ({
    type: badgesActionTypes.CREATE_BADGE_ERROR,
    payload: message
});
export const getBadges = data => ({
    type: badgesActionTypes.GET_BADGES,
    payload: data,
});
export const getBadgesSuccess = data => ({
    type: badgesActionTypes.GET_BADGES_SUCCESS,
    payload: data
});
export const getBadgesError = message => ({
    type: badgesActionTypes.GET_BADGES_ERROR,
    payload: message
});
export const assignBadge = data => ({
    type: badgesActionTypes.ASSIGN_BADGE,
    payload: data,
});
export const assignBadgeSuccess = data => ({
    type: badgesActionTypes.ASSIGN_BADGE_SUCCESS,
    payload: data
});
export const assignBadgeError = message => ({
    type: badgesActionTypes.ASSIGN_BADGE_ERROR,
    payload: message
});
export const editBadge = (data, finalData, Id) => ({
    type: badgesActionTypes.EDIT_BADGE,
    payload: data,
    finalData: finalData,
    Id: Id
});
export const editBadgeSuccess = data => ({
    type: badgesActionTypes.EDIT_BADGE_SUCCESS,
    payload: data
});
export const editBadgeError = message => ({
    type: badgesActionTypes.EDIT_BADGE_ERROR,
    payload: message
});
export const deleteBadge = data => ({
    type: badgesActionTypes.DELETE_BADGE,
    payload: data,
});
export const deleteBadgeSuccess = data => ({
    type: badgesActionTypes.DELETE_BADGE_SUCCESS,
    payload: data
});
export const deleteBadgeError = message => ({
    type: badgesActionTypes.DELETE_BADGE_ERROR,
    payload: message
});