/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { string, object } from "yup";
import { createBadge } from '../../store/Badges/action';
import '../../styles/index.css';
import { Input } from 'antd';
import { DropzoneArea } from 'material-ui-dropzone'
import "./Badges.css";
import Swal from 'sweetalert2'


const createNewBadge = (props) => {
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [file, setFile] = React.useState('');
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: object({
            email: string().required("Required"),
            password: string().required("Required"),
        }),
        onSubmit: (values) => {
            const obj = {
                badgeName: values.email,
                badgeDescription: values.password,
            }
            let finalData = new FormData();
            finalData.append('image', file)
            dispatch(createBadge(obj, finalData))
            Swal.fire({
                title: 'Success',
                text: " Badge created successfully",
                icon: 'success',
                timer: 1000
            })
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        },
    });

    const handleReference = (files) => {
        setFile(files[0])
    }
    return (
        <Container className="BadgeUI" fluid style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: "22vw" }}>
            <Form noValidate onSubmit={formik.handleSubmit} className="">
                <Form.Group style={{ marginTop: '65px', width: "80vw" }} controlId="formBasicEmail">
                    <Form.Label style={{ marginRight: "118px", fontSize: "20px", color: "#707070", fontWeight: "600", fontFamily: "SF Pro Display" }}>Enter badge name</Form.Label>
                    <Form.Control
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder=""
                        isValid={formik.touched.email && !formik.errors.email}
                        isInvalid={formik.touched.email && formik.errors.email}
                    />
                    <Form.Control.Feedback type="invalid" style={{ marginLeft: "-67px", fontSize: "14px" }}>
                        {formik.errors.email}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group style={{ marginTop: '10px', width: "72vw" }} controlId="formBasicPassword">
                    <Form.Label style={{ fontSize: "20px", color: "#707070", fontWeight: "600", fontFamily: "SF Pro Display" }}>Enter description</Form.Label>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        name="password"
                        type="password"
                        rules={[{ required: true }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        placeholder=""
                        isValid={formik.touched.password && !formik.errors.password}
                        isInvalid={formik.touched.password && formik.errors.password}
                        style={{ border: formik.errors.password ? "1px solid #eb2525" : '', borderRadius: "10px" }}
                    />
                    <span style={{ color: "red" }}> {formik.errors.password}</span>
                </Form.Group>
                <DropzoneArea
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit="1"
                    onChange={handleReference}
                />
                <Form.Group>
                    <Button style={{ width: "72vw", marginRight: "46vw" }} variant="primary" type="submit">
                        Submit
                                        </Button>
                </Form.Group>
            </Form>
        </Container>
    )
}
export default createNewBadge;