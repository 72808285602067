import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { createRootReducer } from './combineReducers';
import rootSaga from './combineSagas';
const history = createBrowserHistory();


const middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
middlewares.push(routerMiddleware(history));

const composeEnhancers = composeWithDevTools({});
const store = createStore(
  createRootReducer(history),
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
export {store};


