import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';
import { encrypt, decryption } from '../home/encryptDecrypt'
import { loginActionTypes } from '../constants';

function* handleAdminLogin(request) {
  const { payload } = request;
  const data = encrypt(JSON.stringify(payload))
  try {
    let res = yield call(api.login, data);
    let result = decryption(res.data)
    if (!result.error) {
      localStorage.setItem('Authorization', JSON.stringify(result.data.token))
    }
    yield put({ type: 'ADMIN_LOGIN_SUCCESS', result });
  } catch (err) {
    yield put({ type: 'ADMIN_LOGIN_ERROR', ...err });
  }
}

export function* loginSaga() {
  yield all([
    yield takeLatest(loginActionTypes.ADMIN_LOGIN_REQUEST, handleAdminLogin),
  ]);
}