import React, { Children } from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/configureStore';
import CustomRoute from './router/index';

import './App.css';
const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <CustomRoute history={history} />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
