import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';

import {    codiclustActionTypes,  codiclustVoteCountActionTypes, codiclustDeleteActionTypes } from '../constants';

function* handleFetch(requets) {
    const { payload } = requets;
    try {
        const response = yield call(api.searchStories, payload);  
        yield put({ type: 'FETCH_DATA_SUCCESS', response });
    } catch (err) {
       yield put({type: 'FETCH_DATA_ERROR', ...err});
    }
}

function* handleUpdateVoteCount(requets) {
    const { payload } = requets;
    try {
      yield put({ type: 'UPDATE_VOTE_COUNT_SUCCESS', ...payload});
    } catch (err) {
      yield put({type: 'UPDATE_VOTE_COUNT_ERROR', ...err});
    }
}

function* handleDeleteData(requets) {
  const { payload } = requets;
   try {
    yield put({ type: 'CODICLUST_DELETE_DATA_SUCCESS', ...payload});
  } catch (err) {
    yield put({type: 'CODICLUST_DELETE_DATA_ERROR', ...err});
  }
}


export function* homeData() {     
    yield all([
        yield takeLatest(codiclustActionTypes.FETCH_DATA_REQUEST, handleFetch),
        yield takeLatest(codiclustVoteCountActionTypes.UPDATE_VOTE_COUNT_REQUEST, handleUpdateVoteCount), 
        yield takeLatest(codiclustDeleteActionTypes.CODICLUST_DELETE_DATA_REQUEST, handleDeleteData),
      ]);
}