import { userActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
    users: [],
    Delete: '',
    status: ''
};

const userReducer = (state = initialState, action) => {
    const newState = cloneDeep(state);
    switch (action.type) {
        case userActionTypes.GET_ALL_USERS: {
            return { ...newState, loading: true };
        }
        case userActionTypes.GET_ALL_USERS_SUCCESS: {
            newState.loading = false;
            newState.users = action.result;
            return newState;
        }
        case userActionTypes.GET_ALL_USERS_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case userActionTypes.DELETE_USERS: {
            return { ...newState, loading: true };
        }
        case userActionTypes.DELETE_USERS_SUCCESS: {
            newState.loading = false;
            newState.Delete = action.result;
            return newState;
        }
        case userActionTypes.DELETE_USERS_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case userActionTypes.UPDATE_STATUS: {
            return { ...newState, loading: true };
        }
        case userActionTypes.UPDATE_STATUS_SUCCESS: {
            newState.loading = false;
            newState.status = action.result;
            return newState;
        }
        case userActionTypes.UPDATE_STATUS_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        default:
            return newState;
    }
};

export default userReducer;
