import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, DeleteUsers, updateStatus } from '../../store/Users/action';
import "antd/dist/antd.css";
import { Table, Divider, Button, Input, Space } from "antd";
import styles from "./User.module.scss";
import { Spin, Alert } from 'antd';
import Swal from 'sweetalert2'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';


const Users = (props) => {
    const [state, setState] = React.useState({
        searchText: '',
        searchedColumn: '',
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsers());
    }, []);
    const tableData = useSelector(
        (state) => state.Users
    );
    const deleteUser = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                const obj = {
                    id: data._id
                }
                dispatch(DeleteUsers(obj));
                setTimeout(() => {
                    dispatch(getUsers());
                }, 3000)
                Swal.fire({
                    title: 'Deleted!',
                    text: "Deleted successfully",
                    icon: 'success',
                    timer: 3000
                })
            }
        })
    }
    let textInput = null;
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => { textInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '' });
    };
    const handleStatus = (data) => {
        let Status = data.status === 1 ? 0 : 1
        const obj = {
            userId: data._id,
            status: Status
        }
        dispatch(updateStatus(obj));
        Swal.fire({
            title: 'Success!',
            text: "Updated status successfully",
            icon: 'success',
            timer: 3000
        })
        setTimeout(() => {
            dispatch(getUsers());
        }, 3000)
    }
    let usersColumns = [
        {
            title: "Name",
            dataIndex: "displayName",
            key: "displayName",
            ...getColumnSearchProps('displayName'),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps('email'),
        },
        {
            title: "PhoneNumber",
            dataIndex: "contactDetails",
            key: "contactDetails",
            render: text => text.phoneNumber === '' ? "-" : text.phoneNumber
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            sorter: (a, b) => { return a.role.localeCompare(b.role) },
            render: text => text === '' ? "-" : text
        },
        {
            title: "Points",
            dataIndex: "points",
            key: "points",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.points - b.points,
            render: text => text === '' ? "-" : text
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.status - b.status,
            render: text => text === 1 ? "Active" : "DeActive"
        },
        {
            title: "Action",
            key: "status",
            dataIndex: "status",
            render: (text, record) => (
                <div>
                    <Button style={{ background: "#3f51b5", color: "white", borderRadius: "16px" }} onClick={(i) => handleStatus(record)}>{text === 0 ? "Activate" : "DeActivate"}</Button>
                    <Divider type="vertical" />
                    <Button style={{ background: "red", color: "white", borderRadius: "16px" }} onClick={(i) => deleteUser(record)}>Delete</Button>
                </div>
            )

        }
    ];
    return (
        <div>
            {tableData.users && !tableData.users.success ?
                <Spin style={{ marginTop: "40vh", marginLeft: "7vw" }} tip="Loading...">
                    <Alert
                    />
                </Spin>
                :
                <Table style={{ border: "1px solid lightgray", paddingRight: "1px", width: "84.5%", marginLeft: "14.5vw", marginTop: "35px" }} dataSource={tableData && tableData.users.data} columns={usersColumns} pagination={{ defaultPageSize: 15 }} />}
        </div>
    )
}
export default Users