import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';
import { encrypt, decryption } from '../home/encryptDecrypt'
import { userActionTypes } from '../constants';

function* handleGetAllUsers() {
    try {
        let res = yield call(api.getUsers);
        let result = decryption(res.data)
        yield put({ type: 'GET_ALL_USERS_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'GET_ALL_USERS_ERROR', ...err });
    }
}
function* handleDeleteUsers(request) {
    const { payload } = request;
    const data = encrypt(JSON.stringify(payload))
    try {
        let res = yield call(api.deleteUser, data);
        let result = decryption(res.data)
        yield put({ type: 'DELETE_USERS_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'DELETE_USERS_ERROR', ...err });
    }
}
function* handleStatus(request) {
    const { payload } = request;
    const data = encrypt(JSON.stringify(payload))
    try {
        let res = yield call(api.updateUserStatus, data);
        let result = decryption(res.data)
        yield put({ type: 'UPDATE_STATUS_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'UPDATE_STATUS_ERROR', ...err });
    }
}
export function* usersSaga() {
    yield all([
        yield takeLatest(userActionTypes.GET_ALL_USERS, handleGetAllUsers),
        yield takeLatest(userActionTypes.DELETE_USERS, handleDeleteUsers),
        yield takeLatest(userActionTypes.UPDATE_STATUS, handleStatus),
    ]);
}