import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReportedPosts, postStatus } from '../../store/ReportedPosts/action';
import "antd/dist/antd.css";
import { Table, Divider, Button, Input } from "antd";
import { Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import "./ReportedPosts.css";
import { Spin, Alert, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import UiModalPopup from '../../components/UiModalPopup/index'
import Swal from 'sweetalert2'

let baseURL = "https://admin.codiclust.de/uploads/"
const ReportedPosts = (props) => {
    const [state, setState] = React.useState({
        file: '',
        searchText: '',
        searchedColumn: '',
        show: false
    });
    let download = state.file
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReportedPosts());
    }, []);
    const posts = useSelector(
        (state) => state.posts.Posts
    );
    const handleDownload = (text) => {
        setState({ ...state, show: true, file: baseURL + text });
    }
    const handlePostStatus = (record, data) => {
        const obj = {
            type: String(data),
            postId: record._id
        }
        dispatch(postStatus(obj))
        Swal.fire({
            title: 'Success!',
            text: "Successfull",
            icon: 'success',
            timer: 5000
        })
        setTimeout(() => {
            dispatch(getReportedPosts());
        }, 3000)
    }
    let textInput = null;
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => { textInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
              </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                    text
                ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    const handleReset = clearFilters => {
        clearFilters();
        setState({ searchText: '' });
    };
    let AllPosts = [
        {
            title: "Name",
            dataIndex: ["user", "displayName"],
            key: "displayName",
            sorter: (a, b) => { return a.user.displayName.localeCompare(b.user.displayName) },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            ...getColumnSearchProps('title'),
            render: (text, record) => (
                <Tooltip title={text}>
                    <span style={{ cursor: "pointer" }}>  {text.slice(0, 15)}{text.length > 15 ? "..." : ''}</span>
                </Tooltip>
            )
        },
        {
            title: "Category type",
            dataIndex: "categoryType",
            key: "categoryType",
            render: text => Object.keys(text).map(bool => text[bool] !== false ? bool : '')
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record) => (
                <Tooltip style={{ textAlign: "justify" }} title={text}>
                    <span style={{ cursor: "pointer" }}>  {text.slice(0, 25)}{text.length > 25 ? "..." : ''}</span>
                </Tooltip>
            )
        },
        {
            title: "File",
            dataIndex: "file",
            key: "file",
            width: "10%",
            render: (text, row) => row.video === '' || row.video === undefined ?
                <img alt='' style={{ width: "102px", height: "52px", cursor: 'pointer' }} onClick={() => handleDownload(text)} src={baseURL + text}></img> : <a style={{ color: "blue", textDecoration: "underline" }} target="_blank" href={row.video} rel="noopener noreferrer">{row.video}</a>
        },
        {
            title: "Status",
            dataIndex: "feedStatus",
            key: "feedStatus",
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.feedStatus - b.feedStatus,
            render: text => text === 0 ? "Allowed" : "Dis-allowed"
        },
        {
            title: "Action",
            key: "status",
            render: (text, record) => (
                <div>
                    <Button style={{ background: "#3f51b5", color: "white", borderRadius: "16px" }} onClick={() => handlePostStatus(record, 0)} >Allow</Button>
                    <Divider type="vertical" />
                    <Button style={{ background: "#3f51b5", color: "white", borderRadius: "16px" }} onClick={() => handlePostStatus(record, 1)}>Dis-Allow</Button>
                    <Divider type="vertical" />
                    <Button style={{ background: "red", color: "white", borderRadius: "16px" }} onClick={() => handlePostStatus(record, 2)}>Delete</Button>
                </div>
            )

        }
    ];
    return (
        <div>
            {posts && !posts.success ?
                <Spin style={{ marginTop: "40vh", marginLeft: "7vw" }} tip="Loading...">
                    <Alert
                    />
                </Spin>
                :
                <Table style={{ width: "85%", border: "1px solid lightgray", paddingRight: "1px", marginLeft: "14.5vw", marginTop: "35px" }} dataSource={posts && posts.data} columns={AllPosts} pagination={{ defaultPageSize: 15 }} />}
            <UiModalPopup title={"Preview"} show={state.show} showSave={false} onClose={() => { setState({ ...state, show: false }) }}>
                <div>
                    <img src={download} style={{ width: "48vw" }} alt='' />
                </div>
            </UiModalPopup>
        </div>
    )
}
export default ReportedPosts;