import { codiclustSearchActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
  
};

const codiclustSearchReducer = (state = initialState, action) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    case codiclustSearchActionTypes.FETCH_SEARCH_REQUEST: {
      return { ...newState, loading: true };
    }
    case codiclustSearchActionTypes.FETCH_SEARCH_SUCCESS: {
      newState.loading = false;
      return newState;
    }   
    case codiclustSearchActionTypes.FETCH_SEARCH_ERROR: {
      return { ...newState, loading: true, errors: action.payload };
    }
    default:
      return newState;
  }
};

export default codiclustSearchReducer;
