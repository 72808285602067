import {
  codiclustActionTypes, codiclustUserActionTypes, codiclustVoteCountActionTypes, codiclustDeleteActionTypes
} from '../constants';

const initialState = {
  loading: true,

};

const homeDataReducer = (state = initialState, action) => {

  // fecth initial data from search //
  switch (action.type) {
    case codiclustActionTypes.FETCH_DATA_REQUEST: {
      return { ...state, loading: true };
    }

    case codiclustActionTypes.FETCH_DATA_SUCCESS: {
      state.loading = false;
      return state;
    }

    case codiclustActionTypes.FETCH_DATA_ERROR: {
      return { ...state, loading: true, errors: '' };
    }

    // update vote count //
    case codiclustVoteCountActionTypes.UPDATE_VOTE_COUNT_REQUEST: {
      return { ...state, loading: true };
    }
    case codiclustVoteCountActionTypes.UPDATE_VOTE_COUNT_SUCCESS: {
      state.loading = false;
      return state;
    }
    case codiclustVoteCountActionTypes.UPDATE_VOTE_COUNT_ERROR: {
      return { ...state, loading: true, errors: action };
    }


    // update vote count //
    case codiclustDeleteActionTypes.CODICLUST_DELETE_DATA_REQUEST: {
      return { ...state, loading: true };
    }
    case codiclustDeleteActionTypes.CODICLUST_DELETE_DATA_SUCCESS: {
      state.loading = false;
      return state;
    }
    case codiclustDeleteActionTypes.CODICLUST_DELETE_DATA_ERROR: {
      return { ...state, loading: true, errors: action };
    }

    // fecth user details //
    case codiclustUserActionTypes.FETCH_USER_REQUEST: {
      return { ...state, loading: true };
    }
    case codiclustUserActionTypes.FETCH_USER_SUCCESS: {
      state.loading = false;
      return state;
    }
    case codiclustUserActionTypes.FETCH_USER_ERROR: {
      return { ...state, loading: true, errors: action };
    }

    default:
      return state;
  }
};

export default homeDataReducer;
