import { reportedPostActionTypes } from '../constants';
export const getReportedPosts = () => ({
    type: reportedPostActionTypes.GET_ALL_REPORTED_POSTS,
    payload: ''
});
export const getReportedPostsSuccess = data => ({
    type: reportedPostActionTypes.GET_ALL_REPORTED_POSTS_SUCCESS,
    payload: data
});
export const getReportedPostsError = message => ({
    type: reportedPostActionTypes.GET_ALL_REPORTED_POSTS_ERROR,
    payload: message
});
export const postStatus = (data) => ({
    type: reportedPostActionTypes.POST_STATUS,
    payload: data
});
export const postStatusSuccess = data => ({
    type: reportedPostActionTypes.POST_STATUS_SUCCESS,
    payload: data
});
export const postStatusError = message => ({
    type: reportedPostActionTypes.POST_STATUS_ERROR,
    payload: message
});