import React from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, Accordion } from 'react-bootstrap';
import styles from './SideBar.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import UiLogo from '../Logo/index';
import BadgeIcon from '../../images/best-seller (1).png';
import postIcon from '../../images/share-post.png';
import treeIcon from '../../images/tree.png';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCreateBadges = () => {
    this.props.history.push('/addBadges');
  };
  handlePosts = () => {
    this.props.history.push('/reportedPosts');
  };
  handleUsers = () => {
    this.props.history.push('/Users');
  };
  handlebadgesList = () => {
    this.props.history.push('/allBadges');
  };
  getKey = () => {
    if (this.props.history.location.pathname === '/Users') {
      return '0';
    } else if (this.props.history.location.pathname === '/reportedPosts') {
      return '1';
    } else if (this.props.history.location.pathname === '/addBadges') {
      return '2';
    } else if (this.props.history.location.pathname === '/allBadges') {
      return '2';
    } else if (this.props.history.location.pathname === '/assignBadges') {
      return '2';
    } else {
      return '0';
    }
  };
  render() {
    return (
      <aside
        className={cx(styles.sideBarDark, styles.elevation, {
          [styles.active]: '1',
        })}
      >
        <Link to="/users" className={styles.brandLink}>
          <div className="logo logo--dash">
            <UiLogo color={'dark'} width={'200px'} height={'200px'} />
          </div>
          <p
            style={{ marginTop: '11px', marginLeft: '-1vw', fontSize: '28px' }}
            className={cx(styles.brandText)}
          >
            Admin Panel
          </p>
        </Link>

        <div className={styles.sideBar}>
          <Accordion defaultActiveKey={this.getKey}>
            <Accordion.Toggle onClick={this.handleUsers} as={Nav.Item} eventKey="0">
              <Nav.Item
                style={{
                  background: this.props.history.location.pathname === '/Users' ? '#636161' : '',
                }}
              >
                <Nav.Link style={{ padding: '1.2rem .6rem' }}>
                  <FiUsers className={styles.icon} />
                  Users
                </Nav.Link>
              </Nav.Item>
            </Accordion.Toggle>
            <Accordion.Toggle onClick={this.handlePosts} as={Nav.Item} eventKey="1">
              <Nav.Item
                style={{
                  background:
                    this.props.history.location.pathname === '/reportedPosts' ? '#636161' : '',
                }}
              >
                <Nav.Link style={{ padding: '1.2rem .6rem' }}>
                  <img
                    src={postIcon}
                    style={{ height: '1.5rem', width: '1.5rem', marginRight: '.7vw' }}
                    alt=""
                  />
                  Reported Posts
                </Nav.Link>
              </Nav.Item>
            </Accordion.Toggle>
            <Accordion.Toggle as={Nav.Item} eventKey="2">
              <Nav.Link style={{ padding: '1.2rem .6rem' }}>
                <img
                  src={BadgeIcon}
                  style={{ height: '1.5rem', width: '1.5rem', marginRight: '.7vw' }}
                  alt=""
                />
                Badges
              </Nav.Link>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Nav variant="pills" className="flex-column">
                <Nav.Item
                  style={{
                    background:
                      this.props.history.location.pathname === '/allBadges' ? '#636161' : '',
                  }}
                >
                  <Nav.Link onClick={this.handlebadgesList} style={{ marginLeft: '.7vw' }}>
                    <FaCircle style={{ marginRight: '5px', marginLeft: '1rem' }} />
                    All badges list
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  style={{
                    background:
                      this.props.history.location.pathname === '/addBadges' ? '#636161' : '',
                  }}
                >
                  <Nav.Link onClick={this.handleCreateBadges} style={{ marginLeft: '.7vw' }}>
                    <FaCircle style={{ marginRight: '5px', marginLeft: '1rem' }} />
                    Create badge
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  style={{
                    background:
                      this.props.history.location.pathname === '/assignBadges' ? '#636161' : '',
                  }}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.history.push('/assignBadges');
                    }}
                    style={{ marginLeft: '.7vw' }}
                  >
                    <FaCircle style={{ marginRight: '5px', marginLeft: '1rem' }} />
                    Assign badges
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Accordion.Collapse>
            <Accordion.Toggle as={Nav.Item} eventKey="3">
              <Nav.Link style={{ padding: '1.2rem .6rem' }}>
                <img
                  src={treeIcon}
                  style={{ height: '1.5rem', width: '1.5rem', marginRight: '.7vw' }}
                  alt=""
                />
                Tree
              </Nav.Link>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Nav variant="pills" className="flex-column">
                <Nav.Item
                  style={{
                    background:
                      this.props.history.location.pathname === '/allTreeList' ? '#636161' : '',
                  }}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.history.push('/allTreeList');
                    }}
                    style={{ marginLeft: '.7vw' }}
                  >
                    <FaCircle style={{ marginRight: '5px', marginLeft: '1rem' }} />
                    Tree list
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  style={{
                    background:
                      this.props.history.location.pathname === '/addTree' ? '#636161' : '',
                  }}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.history.push('/addTree');
                    }}
                    style={{ marginLeft: '.7vw' }}
                  >
                    <FaCircle style={{ marginRight: '5px', marginLeft: '1rem' }} />
                    Create Tree
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </aside>
    );
  }
}
const enhancedComponent = withRouter(Sidebar);

export default enhancedComponent;
