import { badgesActionTypes } from '../constants';
import cloneDeep from 'clone-deep';

const initialState = {
    createBadge: '',
    badgesList: [],
    editedBadge: '',
    deleteResult: '',
    assignResult: ''
};

const badgesReducer = (state = initialState, action) => {
    const newState = cloneDeep(state);
    switch (action.type) {
        case badgesActionTypes.CREATE_BADGE: {
            return { ...newState, loading: true };
        }
        case badgesActionTypes.CREATE_BADGE_SUCCESS: {
            newState.loading = false;
            newState.createBadge = action.result;
            return newState;
        }
        case badgesActionTypes.CREATE_BADGE_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case badgesActionTypes.GET_BADGES: {
            return { ...newState, loading: true };
        }
        case badgesActionTypes.GET_BADGES_SUCCESS: {
            newState.loading = false;
            newState.badgesList = action.result;
            return newState;
        }
        case badgesActionTypes.GET_BADGES_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case badgesActionTypes.EDIT_BADGE: {
            return { ...newState, loading: true };
        }
        case badgesActionTypes.EDIT_BADGE_SUCCESS: {
            newState.loading = false;
            newState.editedBadge = action.result;
            return newState;
        }
        case badgesActionTypes.EDIT_BADGE_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case badgesActionTypes.DELETE_BADGE: {
            return { ...newState, loading: true };
        }
        case badgesActionTypes.DELETE_BADGE_SUCCESS: {
            newState.loading = false;
            newState.deleteResult = action.result;
            return newState;
        }
        case badgesActionTypes.DELETE_BADGE_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        case badgesActionTypes.ASSIGN_BADGE: {
            return { ...newState, loading: true };
        }
        case badgesActionTypes.ASSIGN_BADGE_SUCCESS: {
            newState.loading = false;
            newState.assignResult = action.result;
            return newState;
        }
        case badgesActionTypes.ASSIGN_BADGE_ERROR: {
            return { ...newState, loading: true, errors: action.payload };
        }
        default:
            return newState;
    }
};

export default badgesReducer;
