import axios from 'axios';

// Default API will be your root
// const baseURL = "http://18.196.205.59/api/v1";

const baseURL =
  process.env.REACT_APP_ENV === 'production'
  ? process.env.REACT_APP_PRODUCTION_URL
  : process.env.REACT_APP_STAGING_URL;

const api_key = 'DKtKvd5QUZRX0HEWhsbBbodoNtBrkHOdbfGr7xQ8AKIB13';

class Api {
  static headers(headersparams) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': api_key,
      ...headersparams,
    };
  }
  static get(route, key = null) {
    return this.request(route, null, 'GET', key);
  }

  static put(route, params, key = null) {
    return this.request(route, params, 'PUT', key);
  }

  static post(route, params, key) {
    return this.request(route, params, 'POST', key);
  }

  static delete(route, params, key = null) {
    return this.request(route, params, 'DELETE', key);
  }

  static patch(route, params, key = null) {
    return this.request(route, params, 'PATCH', key);
  }

  static request(route, params, verb, key) {
    if (!!route.match('https://')) {
      const url = `${route}`;
      const options = { method: verb, params };
      return axios(url, options);
    } else {
      const host = baseURL;
      const url = `${host}${route}`;
      const options = { method: verb, data: params };
      let keys = { ...key, Authorization: JSON.parse(localStorage.getItem('Authorization')) };
      options.headers = Api.headers(keys);
      return axios(url, options);
    }
  }
}
export default Api;
