/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { string, object } from "yup";
import { adminLoginRequest } from '../../store/login/actions';
import UiLogo from '../../components/Logo/index';
import Icons from '../../themes/icons';
import Images from '../../themes/images';
import '../../styles/index.css';
import Swal from 'sweetalert2'

const login = (props) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: object({
            email: string().email("Invalid email address").required("Required"),
            password: string().min(8, "Must be 6 characters or more").required("Required"),
        }),
        onSubmit: (values) => {
            const obj = {
                email: values.email,
                password: values.password,
                deviceToken: "eX0o3EscfEtsmLrcvS8Gub:APA91bHPb3ZSc75OQq7qZwuUGefVQ049n8vm6NG_v3ECJkoXf7Vi7l3nvMjLakZ6vo8SN7v8FgJPVYl5BQusKhCV9t-s6jqGp8FkOUN8cyCJNksiXqdHlQk07QJ9GJ9dLJg4ZJPxqP_j",
                // loginType: 1
            }
            dispatch(adminLoginRequest(obj))
            setTimeout(() => {
                if (dashboard.adminLogin.length < 1) {
                    Swal.fire({
                        title: 'Error',
                        text: "User not found",
                        icon: 'error',
                        timer: 3000
                    })
                }
            }, 3000)
        },
    });
    const dashboard = useSelector(
        (state) => state.adminLogin
    );
    if (dashboard.adminLogin.success) {
        props.history.push('/Users')
        window.location.reload()
    }
    return (
        <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <Col className="codiclust-bg flex" lg={12} md={12} sm={12}>
                        <Col className="width100 mt-20" lg={6} md={6} sm={12} xs={12}>
                            <Col className="ClogoName width100">
                                <UiLogo color={'dark'} width={'50px'} height={'50px'} />
                            </Col>
                        </Col>
                        <Col className="width100 mt-20" lg={6} md={6} sm={12} xs={12}>
                            <Col className="loginForm" >
                                <Col className="adminlogin" >Admin login</Col>
                                <Form noValidate onSubmit={formik.handleSubmit} className="">
                                    <Form.Group style={{ marginLeft: "21px", marginTop: "10px" }} controlId="formBasicEmail">
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            placeholder="Please Enter email"
                                            isValid={formik.touched.email && !formik.errors.email}
                                            isInvalid={formik.touched.email && formik.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group style={{ marginLeft: "21px" }} controlId="formBasicPassword">
                                        <Form.Control
                                            name="password"
                                            type="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            placeholder="Please Enter Password"
                                            isValid={formik.touched.password && !formik.errors.password}
                                            isInvalid={formik.touched.password && formik.errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.password}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group>
                                        <Button variant="primary" type="submit">
                                            Login
                                        </Button>
                                    </Form.Group>
                                </Form>
                                {/* <Col className="fPassword" >Passwort vergessen ?</Col> */}
                            </Col>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </Container>
    )
}
export default login;