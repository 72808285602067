import { treeActionTypes } from '../constants';
export const createTree = (data, finalData) => ({
    type: treeActionTypes.CREATE_TREE,
    payload: data,
    finalData: finalData
});
export const createTreeSuccess = data => ({
    type: treeActionTypes.CREATE_TREE_SUCCESS,
    payload: data
});
export const getTrees = data => ({
    type: treeActionTypes.GET_TREES,
    payload: data,
});
export const getTreesSuccess = data => ({
    type: treeActionTypes.GET_TREES_SUCCESS,
    payload: data
});
export const editTree = (data, finalData) => ({
    type: treeActionTypes.EDIT_TREE,
    payload: data,
    finalData: finalData,
});
export const editTreeSuccess = data => ({
    type: treeActionTypes.EDIT_TREE_SUCCESS,
    payload: data
});