import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import adminLoginReducer from './login/reducer';
import codiclustSearchReducer from './search/reducer';
import homeDataReducer from './home/reducer';
import userReducer from './Users/reducer'
import reportedPostsReducer from './ReportedPosts/reducer'
import badgesReducer from './Badges/reducer'
import treeReducer from './Tree/reducer'

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    adminLogin: adminLoginReducer,
    SearchReducer: codiclustSearchReducer,
    homeData: homeDataReducer,
    Users: userReducer,
    posts: reportedPostsReducer,
    badges: badgesReducer,
    tree: treeReducer
  })