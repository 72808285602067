import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import './index.css'

class UiModalPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <div  >
                <Modal dialogClassName="PopupCard" show={this.props.show} onHide={this.handleClose} backdrop="static">
                    <Modal.Title >
                        {this.props.title}
                    </Modal.Title>
                    <Modal.Body>
                        {this.props.children}

                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.showSave === false ? '' :
                            <Button className="SendButton" onClick={this.props.onSave} >Save</Button>}
                        <Button className="PopupBack" onClick={this.props.onClose} >Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => ({

});
export default connect(mapStateToProps)(UiModalPopup);
