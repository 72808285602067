export const loginActionTypes = {
    ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_ERROR: 'ADMIN_LOGIN_ERROR'
}

export const userActionTypes = {
    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    DELETE_USERS: 'DELETE_USERS',
    DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR: 'DELETE_USERS_ERROR',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_ERROR: 'UPDATE_STATUS_ERROR'
}
export const reportedPostActionTypes = {
    GET_ALL_REPORTED_POSTS: 'GET_ALL_REPORTED_POSTS',
    GET_ALL_REPORTED_POSTS_SUCCESS: 'GET_ALL_REPORTED_POSTS_SUCCESS',
    GET_ALL_REPORTED_POSTS_ERROR: 'GET_ALL_REPORTED_POSTS_ERROR',
    POST_STATUS: ' POST_STATUS',
    POST_STATUS_SUCCESS: 'POST_STATUS_SUCCESS',
    POST_STATUS_ERROR: 'POST_STATUS_ERROR'
}

export const treeActionTypes = {
    CREATE_TREE: 'CREATE_TREE',
    CREATE_TREE_SUCCESS: 'CREATE_TREE_SUCCESS',
    GET_TREES: 'GET_TREES',
    GET_TREES_SUCCESS: 'GET_TREES_SUCCESS',
    EDIT_TREE: 'EDIT_TREE',
    EDIT_TREE_SUCCESS: 'EDIT_TREE_SUCCESS',
}
export const badgesActionTypes = {
    CREATE_BADGE: 'CREATE_BADGE',
    CREATE_BADGE_SUCCESS: 'CREATE_BADGE_SUCCESS',
    CREATE_BADGE_ERROR: 'CREATE_BADGE_ERROR',
    GET_BADGES: 'GET_BADGES',
    GET_BADGES_SUCCESS: 'GET_BADGES_SUCCESS',
    GET_BADGES_ERROR: 'GET_BADGES_ERROR',
    EDIT_BADGE: 'EDIT_BADGE',
    EDIT_BADGE_SUCCESS: 'EDIT_BADGE_SUCCESS',
    EDIT_BADGE_ERROR: 'EDIT_BADGE_ERROR',
    DELETE_BADGE: 'DELETE_BADGE',
    DELETE_BADGE_SUCCESS: 'DELETE_BADGE_SUCCESS',
    DELETE_BADGE_ERROR: 'DELETE_BADGE_ERROR',
    ASSIGN_BADGE: 'ASSIGN_BADGE',
    ASSIGN_BADGE_SUCCESS: 'ASSIGN_BADGE_SUCCESS',
    ASSIGN_BADGE_ERROR: 'ASSIGN_BADGE_ERROR',
}
export const codiclustActionTypes = {
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
    FETCH_DATA_ERROR: 'FETCH_DATA_ERROR'
}

export const codiclustVoteCountActionTypes = {
    UPDATE_VOTE_COUNT_REQUEST: 'UPDATE_VOTE_COUNT_REQUEST',
    UPDATE_VOTE_COUNT_SUCCESS: 'UPDATE_VOTE_COUNT_SUCCESS',
    UPDATE_VOTE_COUNT_ERROR: 'UPDATE_VOTE_COUNT_ERROR'
}

export const codiclustDeleteActionTypes = {
    CODICLUST_DELETE_DATA_REQUEST: 'CODICLUST_DELETE_DATA_REQUEST',
    CODICLUST_DELETE_DATA_SUCCESS: 'CODICLUST_DELETE_DATA_SUCCESS',
    CODICLUST_DELETE_DATA_ERROR: 'CODICLUST_DELETE_DATA_ERROR'
}

export const codiclustUserActionTypes = {
    FETCH_USER_REQUEST: '@@codiclustUser/FETCH_USER_REQUEST',
    FETCH_USER_SUCCESS: '@@codiclustUser/FETCH_USER_SUCCESS',
    FETCH_USER_ERROR: '@@codiclustUser/FETCH_USER_ERROR'
}

export const codiclustSearchActionTypes = {
    FETCH_SEARCH_REQUEST: '@@codiclustSearch/FETCH_SEARCH_REQUEST',
    FETCH_SEARCH_SUCCESS: '@@codiclustSearch/FETCH_SEARCH_SUCCESS',
    FETCH_SEARCH_ERROR: '@@codiclustSearch/FETCH_SEARCH_ERROR'
}