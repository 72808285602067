import React from 'react';
import { Route } from 'react-router-dom';
import login from '../container/login/index';
import Users from '../container/Users/index'
import ReportedPosts from '../container/ReportedPosts/index'
import { LayoutOne, LayoutTwo } from '../components/Layout/index';
import createNewBadge from '../container/Badges/createBadge'
import AllbadgesList from '../container/Badges/AllBadgesList'
import AssignBadge from '../container/Badges/AssignBadge'
import TreeList from '../container/Tree/allTreeList'
import CreateTree from '../container/Tree/createtree'

class CustomRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  render() {
    return (
      <React.Fragment>
        <RouteWrapper exact path="/" component={login} layout={LayoutOne} />
        <RouteWrapper exact path="/Users" component={Users} layout={LayoutTwo} />
        <RouteWrapper exact path="/reportedPosts" component={ReportedPosts} layout={LayoutTwo} />
        <RouteWrapper exact path="/addBadges" component={createNewBadge} layout={LayoutTwo} />
        <RouteWrapper exact path="/allBadges" component={AllbadgesList} layout={LayoutTwo} />
        <RouteWrapper exact path="/assignBadges" component={AssignBadge} layout={LayoutTwo} />
        <RouteWrapper exact path="/allTreeList" component={TreeList} layout={LayoutTwo} />
        <RouteWrapper exact path="/addTree" component={CreateTree} layout={LayoutTwo} />
      </React.Fragment>
    )
  }
}
function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}
export default CustomRoute;