/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { string, object } from "yup";
import { createTree } from '../../store/Tree/action';
import '../../styles/index.css';
import { Input } from 'antd';
import { DropzoneArea } from 'material-ui-dropzone'
import "../Badges/Badges.css";
import Swal from 'sweetalert2'


const AddTree = (props) => {
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [file, setFile] = React.useState('');
    const formik = useFormik({
        initialValues: {
            treeName: "",
            treeDescription: "",
            position: '',
            points: ''
        },
        validationSchema: object({
            treeName: string().required("Required"),
            treeDescription: string().required("Required"),
            position: string().required("Required"),
            points: string().required("Required"),
        }),
        onSubmit: (values) => {
            const obj = {
                treeName: values.treeName,
                treeDescription: values.treeDescription,
                position: values.position,
                requiredPoints: values.points,
            }
            let finalData = new FormData();
            finalData.append('image', file)
            dispatch(createTree(obj, finalData))
            Swal.fire({
                title: 'Success',
                text: " Tree created successfully",
                icon: 'success',
                timer: 1000
            })
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        },
    });

    const handleReference = (files) => {
        setFile(files[0])
    }
    return (
        <Container className="BadgeUI" fluid style={{ paddingLeft: '0px', paddingRight: '0px', marginLeft: "22vw" }}>
            <Form noValidate onSubmit={formik.handleSubmit} className="">
                <Form.Group style={{ marginTop: '65px', width: "80vw" }} controlId="formBasicEmail">
                    <Form.Label
                        style={{
                            marginRight: "118px", fontSize: "20px", color: "#707070", fontWeight: "600",
                            fontFamily: "SF Pro Display"
                        }}>
                        Enter Tree Name</Form.Label>
                    <Form.Control
                        name="treeName"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.treeName}
                        placeholder=""
                        isValid={formik.touched.treeName && !formik.errors.treeName}
                        isInvalid={formik.touched.treeName && formik.errors.treeName}
                    />
                    <Form.Control.Feedback type="invalid" style={{ marginLeft: "-67px", fontSize: "14px" }}>
                        {formik.errors.treeName}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group style={{ marginTop: '10px', width: "72vw" }} controlId="formBasicPassword">
                    <Form.Label
                        style={{
                            fontSize: "20px", color: "#707070", fontWeight: "600",
                            fontFamily: "SF Pro Display"
                        }}>Enter Description</Form.Label>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        name="treeDescription"
                        type="password"
                        rules={[{ required: true }]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.treeDescription}
                        placeholder=""
                        isValid={formik.touched.treeDescription && !formik.errors.treeDescription}
                        isInvalid={formik.touched.treeDescription && formik.errors.treeDescription}
                        style={{ border: formik.errors.treeDescription ? "1px solid #eb2525" : '', borderRadius: "10px" }}
                    />
                    <span style={{ color: "red" }}> {formik.errors.treeDescription}</span>
                </Form.Group>
                <Form.Group style={{ marginTop: '65px', width: "80vw" }} controlId="formBasicEmail">
                    <Form.Label
                        style={{
                            marginRight: "118px", fontSize: "20px", color: "#707070", fontWeight: "600",
                            fontFamily: "SF Pro Display"
                        }}>
                        Enter Position</Form.Label>
                    <Form.Control
                        name="position"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.position}
                        placeholder=""
                        isValid={formik.touched.position && !formik.errors.position}
                        isInvalid={formik.touched.position && formik.errors.position}
                    />
                    <Form.Control.Feedback type="invalid" style={{ marginLeft: "-67px", fontSize: "14px" }}>
                        {formik.errors.position}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group style={{ marginTop: '65px', width: "80vw" }} controlId="formBasicEmail">
                    <Form.Label
                        style={{
                            marginRight: "118px", fontSize: "20px", color: "#707070", fontWeight: "600",
                            fontFamily: "SF Pro Display"
                        }}>
                        Enter Required Points</Form.Label>
                    <Form.Control
                        name="points"
                        type="number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.points}
                        placeholder=""
                        isValid={formik.touched.points && !formik.errors.points}
                        isInvalid={formik.touched.points && formik.errors.points}
                    />
                    <Form.Control.Feedback type="invalid" style={{ marginLeft: "-67px", fontSize: "14px" }}>
                        {formik.errors.points}
                    </Form.Control.Feedback>
                </Form.Group>
                <DropzoneArea
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                    filesLimit="1"
                    onChange={handleReference}
                />
                <Form.Group>
                    <Button style={{ width: "72vw", marginRight: "46vw" }} variant="primary" type="submit">
                        Submit
                                        </Button>
                </Form.Group>
            </Form>
        </Container>
    )
}
export default AddTree;