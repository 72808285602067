import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';
import { encrypt, decryption } from '../home/encryptDecrypt'
import { reportedPostActionTypes } from '../constants';

function* handleGetAllReportedPosts() {
    try {
        let res = yield call(api.getReportedPosts);
        let result = decryption(res.data)
        yield put({ type: 'GET_ALL_REPORTED_POSTS_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'GET_ALL_REPORTED_POSTS_ERROR', ...err });
    }
}
function* handlePostStatus(request) {
    const { payload } = request;
    const data = encrypt(JSON.stringify(payload))
    try {
        let res = yield call(api.postStatus, data);
        let result = decryption(res.data)
        yield put({ type: 'POST_STATUS_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'POST_STATUS_ERROR', ...err });
    }
}
export function* reportedPostsSaga() {
    yield all([
        yield takeLatest(reportedPostActionTypes.GET_ALL_REPORTED_POSTS, handleGetAllReportedPosts),
        yield takeLatest(reportedPostActionTypes.POST_STATUS, handlePostStatus)
    ]);
}