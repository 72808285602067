
import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/sagas';
import { usersSaga } from './Users/sagas';
import { homeData } from './home/sagas';
import { searchSaga } from './search/sagas';
import { reportedPostsSaga } from './ReportedPosts/sagas';
import { badgesSaga } from './Badges/sagas';
import { treeSaga } from './Tree/sagas'

export default function* rootSaga() {
  yield all([
    call(homeData),
    call(loginSaga),
    call(searchSaga),
    call(usersSaga),
    call(reportedPostsSaga),
    call(badgesSaga),
    call(treeSaga)
  ]);
}

