import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';
import { encrypt, decryption } from '../home/encryptDecrypt'
import { badgesActionTypes } from '../constants';


function* newBadge(request) {
    const details = encrypt(JSON.stringify(request.payload))
    request.finalData.append('data', JSON.stringify(details))
    try {
        let res = yield call(api.createNewBadge, request.finalData);
        let result = decryption(res.data)
        yield put({ type: 'CREATE_BADGE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'CREATE_BADGE_ERROR', ...err });
    }
}
function* badgesList() {
    try {
        let res = yield call(api.getBadges);
        let result = decryption(res.data)
        yield put({ type: 'GET_BADGES_SUCCESS', result });
        return result;
    } catch (err) {
        yield put({ type: 'GET_BADGES_ERROR', ...err });
    }
}
function* editBadges(request) {
    const details = encrypt(JSON.stringify(request.payload))
    request.finalData.append('data', JSON.stringify(details))
    const obj = {
        Id: request.Id,
        data: request.finalData
    }
    try {
        let res = yield call(api.editBadge, obj);
        let result = decryption(res.data)
        yield put({ type: 'EDIT_BADGE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'EDIT_BADGE_ERROR', ...err });
    }
}
function* deleteBadges(request) {
    const { payload } = request;
    const data = encrypt(JSON.stringify(payload))
    try {
        let res = yield call(api.deleteBadge, data);
        let result = decryption(res.data)
        yield put({ type: 'DELETE_BADGE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'DELETE_BADGE_ERROR', ...err });
    }
}
function* assignBadge(request) {
    const { payload } = request;
    const data = encrypt(JSON.stringify(payload))
    try {
        let res = yield call(api.assignBadge, data);
        let result = decryption(res.data)
        yield put({ type: 'ASSIGN_BADGE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'ASSIGN_BADGE_ERROR', ...err });
    }
}
export function* badgesSaga() {
    yield all([
        yield takeLatest(badgesActionTypes.CREATE_BADGE, newBadge),
        yield takeLatest(badgesActionTypes.GET_BADGES, badgesList),
        yield takeLatest(badgesActionTypes.EDIT_BADGE, editBadges),
        yield takeLatest(badgesActionTypes.DELETE_BADGE, deleteBadges),
        yield takeLatest(badgesActionTypes.ASSIGN_BADGE, assignBadge),
    ]);
}