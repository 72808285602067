import { loginActionTypes } from '../constants';

// export named actions
export const adminLoginRequest = value => ({
    type: loginActionTypes.ADMIN_LOGIN_REQUEST,
    payload: value
});
export const adminLoginSuccess = data => ({ 
    type: loginActionTypes.ADMIN_LOGIN_SUCCESS,
     payload: data
});
export const adminLoginError = message => ({ 
    type: loginActionTypes.ADMIN_LOGIN_ERROR,
     payload: message 
});