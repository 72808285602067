import Api from './api_config';

export default {
   login: params => Api.post('/auth/signInDashboard', params),
   getUsers: () => Api.get('/user/getAllUser/0/10000000'),
   deleteUser: params => Api.post('/user/deleteUser', params),
   updateUserStatus: params => Api.put('/user/updateStatus', params),
   getReportedPosts: () => Api.get('/feedPost/getReportedPost/0/10000000'),
   createNewBadge: params => Api.post('/badge/createBadge', params),
   getBadges: () => Api.get('/badge/getAllBadge'),
   editBadge: params => Api.put(`/badge/editBadgeById/${params.Id}`, params.data),
   deleteBadge: params => Api.post('/badge/deleteBadge', params),
   assignBadge: params => Api.put('/badge/assignBadge', params),
   postStatus: params => Api.post('/feedPost/deletefeedPost', params),
   createNewTree: params => Api.post('/trees/createTree', params),
   getTrees: () => Api.get('/trees/getAllTrees'),
   treeEdit: params => Api.put("/trees/editTree", params.data),
};