import { call, all, put, takeLatest } from 'redux-saga/effects';
import api from '../../services/index';
import { encrypt, decryption } from '../home/encryptDecrypt'
import { treeActionTypes } from '../constants';


function* newTree(request) {
    const details = encrypt(JSON.stringify(request.payload))
    request.finalData.append('data', JSON.stringify(details))
    try {
        let res = yield call(api.createNewTree, request.finalData);
        let result = decryption(res.data)
        yield put({ type: 'CREATE_TREE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'CREATE_BADGE_ERROR', ...err });
    }
}
function* getTree() {
    try {
        let res = yield call(api.getTrees);
        let result = decryption(res.data)
        yield put({ type: 'GET_TREES_SUCCESS', result });
        return result;
    } catch (err) {
        yield put({ type: 'GET_BADGES_ERROR', ...err });
    }
}
function* editTree(request) {
    const details = encrypt(JSON.stringify(request.payload))
    request.finalData.append('data', JSON.stringify(details))
    const obj = {
        data: request.finalData
    }
    try {
        let res = yield call(api.treeEdit, obj);
        let result = decryption(res.data)
        yield put({ type: 'EDIT_TREE_SUCCESS', result });
    } catch (err) {
        yield put({ type: 'EDIT_BADGE_ERROR', ...err });
    }
}
export function* treeSaga() {
    yield all([
        yield takeLatest(treeActionTypes.CREATE_TREE, newTree),
        yield takeLatest(treeActionTypes.GET_TREES, getTree),
        yield takeLatest(treeActionTypes.EDIT_TREE, editTree),
    ]);
}