import React from "react";
import { withRouter } from 'react-router';
import { Navbar, Nav, Button } from "react-bootstrap";
import Swal from 'sweetalert2'

class Navbars extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleLogout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                localStorage.clear();
                this.props.history.push("/")
            }
        })
    }
    render() {
        return (
            <Navbar style={{ height: '65px', width: 'auto', border: "1px solid", borderBottomColor: "lightgray", borderTopColor: "lightgray" }} expand='lg' >
                <Navbar.Collapse id="basic-navbar-nav">
                </Navbar.Collapse>
                <span onClick={this.handleLogout} style={{ color: "rgb(74 78 79)", fontWeight: "600", fontSize: "23px", cursor: "pointer" }} > Logout </span>
            </Navbar>
        );
    }
}
const enhancedComponent = (withRouter(Navbars));
export default enhancedComponent;