import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBadges, editBadge, deleteBadge } from '../../store/Badges/action';
import "antd/dist/antd.css";
import { Table, Divider, Input } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import UiModalPopup from '../../components/UiModalPopup/index'
import { Form, Button } from 'react-bootstrap';
import './Badges.css'
import { DropzoneArea } from 'material-ui-dropzone'
import { Spin, Alert } from 'antd';
import Swal from 'sweetalert2'

let baseURL = "https://admin.codiclust.de/uploads/"

const AllBadgesList = (props) => {
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        show: false,
        record: '',
        showDropzone: false,
        file: '',
        badgeName: '',
        badgeDescription: ''
    });
    useEffect(() => {
        dispatch(getBadges())
    }, []);
    const badgesData = useSelector(
        (state) => state.badges.badgesList
    );
    const handleEditBadge = (data) => {
        setState({ ...state, show: true, record: data, showDropzone: false });
    }
    const handleDeleteBadge = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                const obj = {
                    id: data._id
                }
                dispatch(deleteBadge(obj))
                setTimeout(() => {
                    dispatch(getBadges());
                }, 3000)
                Swal.fire({
                    title: 'Deleted!',
                    text: "Deleted successfully",
                    icon: 'success',
                    timer: 3000
                })
            }
        })
    }
    const handleSave = () => {
        setState({ ...state, show: false })
        let badge = state.file === '' ? state.record.image : state.file
        let Id = state.record._id
        const obj = {
            badgeName: state.badgeName === '' ? state.record.badgeName : state.badgeName,
            badgeDescription: state.badgeDescription === '' ? state.record.badgeDescription : state.badgeDescription,
        }
        let finalData = new FormData();
        finalData.append('image', badge)
        dispatch(editBadge(obj, finalData, Id))
        Swal.fire({
            title: 'Updated!',
            text: "Updated successfully",
            icon: 'success',
            timer: 3000
        })
        setTimeout(() => {
            dispatch(getBadges());
        }, 3000)
    }
    let listColumns = [
        {
            title: "Badge Name",
            dataIndex: "badgeName",
            key: "badgeName",
            sorter: (a, b) => { return a.badgeName.localeCompare(b.badgeName) },
        },
        {
            title: "Description",
            dataIndex: "badgeDescription",
            key: "badgeDescription"
        },
        {
            title: "Badge",
            dataIndex: "image",
            key: "image",
            render: text =>
                <img style={{ width: "102px", height: "52px", cursor: 'pointer' }} src={baseURL + text} alt=''></img>
        },
        {
            title: "Action",
            key: "status",
            render: (text, record) => (
                <div>
                    <MdEdit style={{ width: "21px", height: "21px", cursor: "pointer" }} onClick={(i) => handleEditBadge(record)} />
                    <Divider type="vertical" />
                    <MdDelete style={{ width: "21px", height: "21px", cursor: "pointer" }} onClick={(i) => handleDeleteBadge(record)} />
                </div>
            )

        }
    ];
    return (
        <div>
            {!badgesData.success ?
                <Spin style={{ marginTop: "40vh", marginLeft: "7vw" }} tip="Loading...">
                    <Alert
                    />
                </Spin>
                :
                <Table style={{ border: "1px solid lightgray", paddingRight: "1px", width: "80.3%", marginLeft: "241px", marginTop: "35px" }} dataSource={badgesData.data} columns={listColumns} pagination={{ defaultPageSize: 15 }} />}
            <UiModalPopup title={"Edit Badge"} show={state.show} showSave={true} onSave={handleSave} onClose={() => { setState({ ...state, show: false }) }}>
                <div>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ color: "#585858", fontSize: '20px', fontFamily: "SF Pro Display" }} > Badge name</Form.Label>
                        <Form.Control
                            style={{ marginLeft: "2vw" }}
                            name="email"
                            type="text"
                            defaultValue={state.record.badgeName}
                            onChange={(e) => { setState({ ...state, badgeName: e.target.value }) }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label style={{ color: "#585858", fontSize: '20px', fontFamily: "SF Pro Display" }} >Description</Form.Label><br />
                        <TextArea
                            style={{ width: "43vw" }}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            defaultValue={state.record.badgeDescription}
                            onChange={(e) => { setState({ ...state, badgeDescription: e.target.value }) }}
                            name=""
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label style={{ color: "#585858", fontSize: '20px', fontFamily: "SF Pro Display" }} >Badge</Form.Label><br />
                        {!state.showDropzone ? <img src={baseURL + state.record.image} style={{ height: "35vh" }} alt='' /> : ''}<br />
                    </Form.Group>
                    {!state.showDropzone ?
                        <Button className="uploadButton" onClick={() => { setState({ ...state, showDropzone: true }) }} >Upload new!</Button>
                        :
                        <div style={{ marginTop: "-8vh" }}>
                            <DropzoneArea
                                acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                                filesLimit="1"
                                onChange={(files) => { setState({ ...state, file: files[0] }) }}
                            />
                        </div>
                    }
                </div>
            </UiModalPopup>
        </div>
    )
}
export default AllBadgesList